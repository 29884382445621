<template>
  <div class="public-container">
    <div class="public-sale">
      <div class="public-sale" v-if="!isPageLoad">
        <circle-c class="circle-load"></circle-c>
      </div>
      <div v-else class="public-sale">
        <sale-cards
          v-for="(card, index) in getGroups"
          :key="index"
          :card="card"
          :showAdminLink="showAdminLink"
          class="card"
          @openGroup="openGroup(card)"
        ></sale-cards>

        <teleport to="body">
          <transition name="fade">
            <group-infomation
              v-if="showFullInfo"
              :groupInfo="currentGroup"
              :currentAPI="createdClass"
              :showAdminLink="showAdminLink"
              class="group-infomation"
              @closeWindow="showFullInfo = false"
            ></group-infomation>
          </transition>
        </teleport>
      </div>

      <ScrollTop class="test"> </ScrollTop>
      <infinity-scroll @intersect="intersected" id="tt" class="observe" />
    </div>

    <aside class="settings" :class="{ show: getSettingsState, 'settings-height': isLoadPage }">
      <settings-bar
        :currentAPI="createdClass"
        @requestNewPage="requestNewPage"
        @updateByFilter="updatePage"
      ></settings-bar>
    </aside>
  </div>
  <!-- <div class="public-container">
    <div class="public-container">
      <span class="text">
        Страница находится в разработке
      </span>
      <img src="/icons/tools.png" class="image">
    </div>
  </div> -->
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import SaleCards from '@/components/public-sale/SaleCards.vue';
import GroupInfomation from '@/components/public-sale/GroupInfomation.vue';
import CircleC from '@/components/atom/loader/Circle.vue';
import SettingsBar from '@/components/vkMenu/settings/PublicSettingsBar.vue';
import InfinityScroll from '@/components/pagination/InfinityScroll.vue';
import ScrollTop from '@/components/atom/button/scroll/ScrollTop.vue';

export default {
  computed: {
    ...mapGetters('vkConfiguration', ['getSettingsState']),
    ...mapGetters('publicSale', ['getGroups', 'isPageLoad']),
  },
  components: {
    SaleCards,
    GroupInfomation,
    CircleC,
    SettingsBar,
    InfinityScroll,
    ScrollTop,
  },
  data: () => ({
    cards: [],
    showFullInfo: false,
    currentGroup: {},
    showAdminLink: true,
  }),
  methods: {
    ...mapMutations('vkValues', ['resetFilter']),
    ...mapActions('publicSale', ['requestGroups', 'requestGroupByInfinity']),

    updatePage() {
      this.updateVisibleAdminLink();
      this.requestGroups();
    },

    requestNewPage() {},

    updateVisibleAdminLink(showAdminLink = true) {
      this.showAdminLink = showAdminLink;
    },

    openGroup(card) {
      this.showFullInfo = true;
      this.currentGroup = card;
    },

    async intersected() {
      if (!this.isPageLoad) {
        return;
      }
      this.requestGroupByInfinity();
    },
  },
  mounted() {
    this.updatePage();
  },
  unmounted() {
    this.resetFilter();
  },
};
</script>

<style lang="scss" scoped>
.observe {
  background: none;
}
.public-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  // flex-direction: column;
  align-items: flex-start;
  font-size: 40px;
}
.text {
  margin: 20px 0 0 0;
}
.test {
  position: fixed;
  bottom: 27px;
  right: calc(41vw - 23%);
  z-index: 15;
}
.image {
  width: 150px;
  height: 150px;

  margin: 100px 0;
}
.settings {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  height: auto;
  padding: 0 12px;

  flex-basis: 350px;
  max-width: 350px;
  min-width: 350px;

  background-color: #f7f7f7;
  border-left: 1px solid #d8dfea;

  box-sizing: border-box;
  margin: 0 0 0 auto;

  @media (max-width: 1300px) {
    position: absolute;
    top: 165px;
    right: 0;
    visibility: hidden;
    width: 50%;
    max-width: 350px;
    height: calc(100% - 165px);
    overflow: auto;
    overflow-x: hidden;
  }
}
.show {
  visibility: visible;
}
.settings-height {
  height: auto;
  padding: 0 12px 50px 12px;
}
.public-sale {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;

  width: 100%;
}
.card {
  margin: 5px 10px;
}
.group-infomation {
  position: absolute;

  top: 0;
  left: 0;

  z-index: 100;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.circle-load {
  width: 125px;
  height: 125px;

  margin: 20px auto;
}
</style>
