<template>
  <div class="group-statistic">
    <div
      class="group-statistic__background"
      @click="$emit('closeWindow', { currentGroup: {}, isShow: false })"
    ></div>

    <div class="group-statistic__information">
      <div class="main">
        <img :src="currentGroup?.photo_link" alt="" class="main__img" />

        <div class="main__info">
          <a :href="currentGroup?.group_link" target="_blank" class="title">
            {{ currentGroup?.group_name }}
          </a>

          <div class="main__pannel">
            <p class="main__text">Подписчиков</p>
            <span class="main__text">-</span>
            <p class="main__text">{{ currentGroup?.subscrices }}</p>
          </div>

          <div class="main__pannel">
            <p class="main__text">Доход (месяц)</p>
            <span class="main__text">-</span>
            <p class="main__text">{{ currentGroup?.profit }}</p>
          </div>

          <div
            class="main__pannel"
            v-if="currentGroup.visitors_visitors !== 0.1 && currentGroup.visitors_visitors"
          >
            <p class="main__text">Посетители</p>

            <span class="main__text">-</span>

            <p class="main__text">{{ currentGroup?.visitors_visitors }}</p>
          </div>

          <div
            v-if="currentGroup.reach_reach !== 0.1 && currentGroup.reach_reach"
            class="main__pannel"
          >
            <p class="main__text">Охват</p>

            <span class="main__text">-</span>

            <p class="main__text">{{ currentGroup?.reach_reach }}</p>
          </div>

          <div
            v-if="currentGroup.grow_count !== 0.1 && currentGroup.grow_count"
            class="main__pannel"
          >
            <p class="main__text">Прирост</p>

            <span class="main__text">-</span>

            <p class="main__text">{{ currentGroup?.grow_count }}</p>
          </div>
        </div>
      </div>

      <div class="group-statistic__main">
        <div class="main__btns">
          <a
            v-if="showAdminLink"
            target="_blank"
            :href="currentGroup.admin_link ?? '#'"
            class="main__button"
            >Связаться с продавцом</a
          >

          <base-button
            class="main__button"
            :class="{ visited: wasAddFavorites || currentGroup.fav_flag }"
            @click="addFavorites()"
            >Добавить в избранное</base-button
          >
        </div>

        <div class="price">
          <p class="price__text content">Цена</p>

          <span class="price__text">-</span>

          <p class="price__text">{{ currentGroup?.price }} руб.</p>
        </div>

        <div class="main__container">
          <p class="main__text content">Описание:</p>

          <p class="main__label">{{ currentGroup?.sale_desc }}</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Тип сообщества</p>

          <span class="main__text">-</span>

          <p class="main__text">{{ currentGroup?.type }}</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Cтатистика</p>

          <span class="main__text">-</span>

          <p class="main__text">{{ currentGroup?.cov_stat_link }}</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Cпособ раскрутки</p>

          <span class="main__text">-</span>

          <p v-if="currentGroup?.prom !== undefined" class="main__text">{{ currentGroup?.prom }}</p>
          <p v-else class="main__text">Не указано</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Источник расходов</p>

          <span class="main__text">-</span>

          <p v-if="currentGroup.srs_exp !== undefined" class="main__text">
            {{ currentGroup?.srs_exp }}
          </p>
          <p v-else class="main__text">Не указано</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Источник доходов</p>

          <span class="main__text">-</span>

          <p v-if="currentGroup.srs_prof !== undefined" class="main__text">
            {{ currentGroup?.srs_prof }}
          </p>
          <p v-else class="main__text">Не указано</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Описание контента</p>

          <span class="main__text">-</span>

          <p v-if="currentGroup.content !== undefined" class="main__text">
            {{ currentGroup?.content }}
          </p>
          <p v-else class="main__text">Не указано</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Способ поддержки группы</p>

          <span class="main__text">-</span>

          <p v-if="currentGroup.support !== undefined" class="main__text">
            {{ currentGroup?.support }}
          </p>
          <p v-else class="main__text">Не указано</p>
        </div>

        <div class="main__pannel">
          <p class="main__text content">Передача группы при продаже</p>

          <span class="main__text">-</span>

          <p v-if="currentGroup.ggroup !== undefined" class="main__text">
            {{ currentGroup?.ggroup }}
          </p>
          <p v-else class="main__text">Не указано</p>
        </div>
      </div>

      <close-icon
        class="close-btn"
        color="#000000"
        @click="$emit('closeWindow', { currentGroup: {}, isShow: false })"
      ></close-icon>
    </div>
  </div>
</template>

<script>
import BaseButton from '@/components/common/BaseButton.vue';
import CloseIcon from '@/components/atom/html-icon/symbol/CloseIcon.vue';
import RequestApi from '@/api/vk';

export default {
  components: {
    CloseIcon,
    // ArrowIcon,
    BaseButton,
  },
  props: {
    currentAPI: {
      type: Object,
      default() {
        return {};
      },
    },
    groupInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    showAdminLink: {
      type: Boolean,
      default: true,
    },
  },

  computed: {},

  data() {
    return {
      updateChart: false,
      charts: [
        {
          type: '',
          name: '',
        },
      ],

      currentGroup: { ...this.groupInfo },
      currentClass: {},
      isUpdatedPage: false,
      filterParamOnUpdate: 'day',
      wasAddFavorites: false,
    };
  },

  emits: ['closeWindow'],

  methods: {
    checkParam(item) {
      return +item === 0.1 || +item === 0.2 || +item === 0.3 ? '' : item;
    },

    setArrow(item) {
      return +item.grow_count >= 0 ? 'arrow-up' : 'arrow-down';
    },

    async updatePageInfo(value) {
      this.filterParamOnUpdate = value;
      this.isUpdatedPage = true;
      await this.currentClass
        .updateGroupByFilter({ gid: this.currentGroup.id, filter: value })
        .then((resp) => {
          this.updateCurrentGroupInfo(resp);
          this.updateCharts();
        })
        .catch()
        .then(() => {
          this.isUpdatedPage = false;
        });
    },

    updateCurrentGroupInfo(resp) {
      let result = {};
      if (Array.isArray(resp) && resp.length !== 0) {
        const [firstItem] = resp;
        result = firstItem;
      } else {
        result = resp;
      }

      const newJSON = result;
      newJSON.subscrices = new Intl.NumberFormat('ru-RU').format(+newJSON.sub_count);
      newJSON.coverage = new Intl.NumberFormat('ru-RU').format(+newJSON.reach_reach);

      const cov = +newJSON.reach_reach / +newJSON.sub_count;
      const percent = (Number(cov.toFixed(2)) * 100).toFixed();
      newJSON.covpercent = `${percent}%`;

      newJSON.visitors = new Intl.NumberFormat('ru-RU').format(+newJSON.visitors_visitors);
      newJSON.grow = new Intl.NumberFormat('ru-RU').format(+newJSON.grow_count);

      this.currentGroup = newJSON;
    },

    async addFavorites() {
      const { fav_flag: isFavorite } = this.currentGroup;
      const { id: gid } = this.currentGroup;
      const { userId: uid } = this.$store.getters['auth/authParams'];

      const resultRequest = await this.$store.dispatch('publicSale/toogleFavoriteGroup', { gid, uid, isAdded: !isFavorite });
      if (resultRequest.message && !isFavorite) {
        this.wasAddFavorites = true;
        this.currentGroup.fav_flag = true;
      }
      if (resultRequest.message && isFavorite) {
        this.wasAddFavorites = false;
        this.currentGroup.fav_flag = false;
      }
    },
  },

  mounted() {
    this.$store.dispatch('userPanel/load');
    this.currentClass = new RequestApi();
    this.updateCurrentGroupInfo(this.groupInfo);
  },
};
</script>

<style lang="scss" scoped>
.group-statistic {
  display: flex;

  justify-content: center;
  align-items: center;

  position: relative;
  box-sizing: border-box;

  // .group-statistic__background
  &__background {
    display: block;
    content: "";

    background: #05060767;
    width: 100vw;
    height: 100vh;
  }

  &__main {
    margin: 0 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // .group-statistic__information
  &__information {
    display: flex;
    content: "";
    flex-direction: column;
    position: absolute;
    background: #ffffff;
    border-radius: 10px;
    width: 70%;
    max-width: 950px;
    height: auto;
    overflow: auto;
    padding: 0 0 35px 30px;
    box-sizing: border-box;
    top: 5%;
  }
}

.title {
  display: flex;
  justify-content: flex-start;
  margin: 0px 0px 10px;
  line-height: 2.3rem;
  font-size: 2rem;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  white-space: nowrap;
  text-decoration: none;
  width: 100%;
}

.price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 43px;

  margin: auto 0 0 0;
  &__text {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 15px 0 0;
    font-size: 30px;
  }
}
.main {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  background: #ffffff;
  min-width: 590px;
  border-radius: 10px;
  margin: 60px 20px 10px 20px;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    align-self: flex-start;
    padding: 16px 0;
  }

  &__label {
    min-width: 70%;
    max-width: 1200px;
    min-height: 100px;
    margin: 0 0 0 20px;
    padding: 5px 5px;
    background: #e2ebf2;
    border-radius: 12px;
    box-sizing: border-box;
  }

  &__img {
    width: 200px;
    height: 200px;
    border-radius: 10px;

    margin: 0 25px 0 0;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    min-height: 50px;
    height: 50px;
    background: #5e82a8;
    color: #fff;
    margin: 0 10px 0 0;
    text-decoration: none;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex-direction: column;

    white-space: nowrap;
    min-width: 250px;
    align-self: stretch;
  }

  &__text {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 15px 0 0;
    font-size: 1rem;
    word-break: break-all;
  }

  &__pannel {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    min-height: 30px;
    margin: 2px 0;
  }

  &__btns {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    // height: 30px;
  }

  &__color {
    &_link {
      color: #2b587a;
    }
    &_green {
      color: #7ea65a;
    }
    &_red {
      color: #d44f30;
    }
  }

  &__opacity {
    opacity: 0.6;
  }

  &__arrow {
    margin: 0 5px 0 0;
  }
  &__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 100%;
  }
}

.content {
  white-space: nowrap;
}

.charts {
  display: flex;
  align-items: center;
  justify-content: space-around;

  flex-wrap: wrap;

  margin: 25px;
  max-width: 75%;
  margin: auto;
}
.chart {
  // flex-basis: 50%;
  min-width: 400px;
}

.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 18px;
  height: 18px;

  z-index: 30;
}

.card-panel {
  position: relative;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding: 0 0 0 55px;
  z-index: 50;
  transform: translateX(0);
}
.lock {
  display: flex;
  justify-content: center;
  align-items: center;

  flex-direction: column;

  width: 100%;
  &__text {
    line-height: 2rem;
    font-size: 1.5rem;
    color: #2b587a;
  }
}
.image-size {
  width: 20px;
  margin: 0 0 0 10px;
  vertical-align: middle;

  &_big {
    width: 300px;
    height: 300px;
  }
}
.filter {
  min-width: 120px;
  width: 120px;
  height: 100%;
  align-self: flex-start;
  margin: 38px 0 0 20px;
}
.circle-load {
  width: 50px;
  height: 50px;
}

.visited {
  background: #eae51b;
  color: #000;
  border: 6px solid #ebe313;
}
</style>
