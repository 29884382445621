<template>
  <div class="sale-card">
    <div class="sale-card__intro">
      <img :src="card.photo_link" alt="Аватарка" class="sale-card__img" />

      <base-button class="sale-card__btn" @click="$emit('openGroup')"> Подробнее </base-button>
    </div>

    <div class="sale-card__info">
      <div class="sale-card__pub-name">
        <h1 class="sale-card__title" :title="card.group_name">
          {{ card.group_name }}
        </h1>
      </div>

      <p class="sale-card__subtitle">
        {{ card.activity }}
      </p>

      <h2 v-if="card.price" class="sale-card__price">{{ card.price }} руб.</h2>
      <h2 v-else class="sale-card__price"></h2>

      <div v-if="card.sub_count" class="sale-card__discribe">
        <p class="sale-card__text">
          {{ new Intl.NumberFormat("ru-RU").format(+card.sub_count) }}
        </p>

        <p class="sale-card__text">- подписчиков</p>
      </div>
      <div v-else class="sale-card__discribe"></div>

      <div v-if="card.sale_desc" class="sale-card__discribe">
        <p class="sale-card__text">{{ card.profit }} руб/месяц</p>

        <p class="sale-card__text">- доход</p>
      </div>
      <div v-else class="sale-card__discribe"></div>
    </div>

    <!-- <base-button class="sale-card__btn_big" @click.prevent="">
       Связаться с продавцом </base-button> -->
    <a v-if="showAdminLink" target="_blank" :href="card.admin_link" class="sale-card__btn_big">
      Связаться с продавцом
    </a>
  </div>
</template>

<script>
import BaseButton from '@/components/common/BaseButton.vue';

export default {
  components: { BaseButton },
  emits: ['openGroup'],
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    showAdminLink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.sale-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);

  width: 295px;
  height: 430px;

  // .sale-card__intro
  &__intro {
    position: relative;
    width: 295px;
    height: 180px;

    margin: 20px 0 0 0;

    &:hover .sale-card__btn {
      visibility: visible;
    }
  }

  // .sale-card__img
  &__img {
    width: 180px;
    height: 180px;

    object-fit: contain;
  }

  // .sale-card__btn
  &__btn {
    position: absolute;
    bottom: -18px;
    left: 29%;

    width: 135px;
    min-height: 45px;
    height: 45px;

    background: #fff;
    color: #5e82a8;
    border: 1px solid #5e82a8;
    visibility: visible;
    // .sale-card__btn_big
    &_big {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 295px;
      min-height: 50px;
      height: 50px;

      background: #5e82a8;
      color: #fff;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
    }
  }
  @media (hover: hover) {
    &__btn {
      position: absolute;
      bottom: 50px;
      left: 29%;

      width: 135px;
      min-height: 45px;
      height: 45px;

      visibility: hidden;
      background: #fff;
      color: #5e82a8;
      border: 1px solid #5e82a8;
      // .sale-card__btn_big
      &_big {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 295px;
        min-height: 50px;
        height: 50px;

        background: #5e82a8;
        color: #fff;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }

  // .sale-card__info
  &__info {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
  }

  // .sale-card__pub-name
  &__pub-name {
    width: 100%;
    margin: 20px 0 10px 0;
    font-size: 20px;
line-height: 32px;
  }

  // .sale-card__icon
  &__icon {
  }

  // .sale-card__title
  &__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 15px;
  }

  // .sale-card__subtitle
  &__subtitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 0 7px 0;

    font-size: 20px;
  }

  // .sale-card__price
  &__price {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    margin: 0 0 7px 0;
    line-height: 25px;
    min-height: 25px;

    font-size: 20px;
  }

  // .sale-card__discribe
  &__discribe {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 22px;
    max-height: 50px;
    overflow: hidden;
  }

  // .sale-card__text
  &__text {
    font-size: 20px;
  }
}

.slot-text {
  color: #000;
}
</style>
